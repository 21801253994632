<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">{{$route.name == 'trainer-competency-criteria-create' ? 'Add Criteria' : 'Edit Criteria' }}</h4>
        </div>
      </div>
    </div>

    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
      
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">

              <div class="row mb-3">
                    
                    <div class="col-lg-3">
                      <label for="remark"> 
                          Competency 
                          <span class="text-danger">*</span>
                      </label>
                    </div>
                    
                    <div class="col-lg-6">

                          <v-select 
                          :options="competencyList" 
                          :reduce="(option)=>option.id"
                          label="name"
                          :clearable="false"
                          :class="{
                            'p-invalid': this.v$.criteria.competency_id.$error,
                          }"
                          v-model="criteria.competency_id"
                          :selectable="(option) => option.id !== criteria.competency_id"
                          />

        
                          <v-errors
                            :vuelidateErrors="{
                              errors: this.v$.criteria.competency_id.$errors,
                              value: 'Competency',
                            }"
                          ></v-errors>

                    </div>

              </div>

              <div class="row mb-3">
                    
                    <div class="col-lg-3">
                      <label for="program"> 
                            Program Name 
                              <span class="text-danger">*</span>
                     </label>
                   </div>
                 
                   <div class="col-lg-6">
                 
                       <div class="d-flex">
                         
                         <div class="d-flex align-items-center me-3">
                             <input
                             type="radio"
                             name="choose"
                             :checked="criteria.all_check"
                             @click="
                             criteria.all_check = 1;
                             criteria.level = '';
                             criteria.assessment_criteria_id = '';
                             "
                             />
                             <label>All</label>
                         </div>
                 
                         <div class="d-flex align-items-center">
                           <input
                           type="radio"
                           name="choose"
                           :checked="!criteria.all_check"
                           @click="
                           criteria.all_check = 0; 
                           criteria.program_ids = []
                           criteria.level = '';
                           criteria.assessment_criteria_id = '';
                           "
                           />
                           <label>Select</label>
                         </div>
                 
                     </div>
                 
                     <v-select  
                     v-show="!criteria.all_check"
                     name="program"
                     id="program"
                     :options="programList" 
                     @option:selected="
                     criteria.level = '';
                     criteria.assessment_criteria_id = '';
                     "
                     @option:deselected="
                     criteria.level = '';
                     criteria.assessment_criteria_id = '';
                     "
                     label="name"
                      :reduce="(option)=>option.id"
                     :loading="!programList.length"
                     :class="{
                     'p-invalid': this.v$.criteria.program_ids.$error,
                     }"
                     v-model="criteria.program_ids"
                     :selectable="(data) => selectedProgram(data)"
                     multiple
                     @click="criteria.assessment_criteria_id = ''"
                     />
                 
                     <v-errors v-show="!criteria.all_check"
                     :vuelidateErrors="{
                     errors: this.v$.criteria.program_ids.$errors,
                     value: 'Program',
                     }"
                     >
                     </v-errors>
                 
                   </div>
                 
              </div>

              <div class="row mb-3">
                    
                    <div class="col-lg-3">
                      <label for="remark"> 
                          Level 
                          <span class="text-danger">*</span>
                      </label>
                    </div>
                    
                    <div class="col-lg-6">

                          <v-select 
                          name="level"
                          id="level"
                          :options="getLevel" 
                          @option:selected="
                          criteria.assessment_criteria_id = '';
                          "
                          :reduce="(option)=>option.name"
                          :clearable="false"
                          label="name"
                          :class="{
                            'p-invalid': this.v$.criteria.level.$error,
                          }"
                          v-model="criteria.level"
                          :selectable="(option) => option.name !== criteria.level?.name"
                          @click="criteria.assessment_criteria_id = ''"
                          />

                          <v-errors
                            :vuelidateErrors="{
                              errors: this.v$.criteria.level.$errors,
                              value: 'Level',
                            }"
                          ></v-errors>

                    </div>

              </div>

              <div class="row mb-3">
                <label for="level" class="col-md-3 form-label">
                  Assessment Criteria
                  <span class="text-danger">*</span>
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'trainer-compentency-criteria-edit',
                      'trainer-compentency-criteria-create',
                    ])
                  "
                >
                  <v-select
                    class="custom-selectCSS"
                    v-model="criteria.assessment_criteria_id"
                    label="name"
                    :options="filterAssessmentCriteria"
                    :clearable="false"
                    :reduce="(name) => name.id"
                    :class="{
                      'p-invalid':
                        v$.criteria.assessment_criteria_id.$error ||
                        errorFor('assessment_criteria_id'),
                    }"
                  >
                  </v-select>
                  <v-errors
                    :serverErrors="errorFor('assessment_criteria_id')"
                    :vuelidateErrors="{
                      errors: v$.criteria.assessment_criteria_id.$errors,
                      value: 'Evaluating Criteria',
                    }"
                  ></v-errors>
                </div>

                <div
                  class="col-md-6"
                  v-else-if="
                    hasPermissions(['trainer-compentency-criteria-list'])
                  "
                >
                  <v-select
                    class="custom-selectCSS"
                    v-model="criteria.assessment_criteria_id"
                    label="name"
                    disabled
                    :clearable="false"
                  >
                  </v-select>
                </div>
              </div>

              <div class="row mb-3">
                <label for="lowest_score" class="col-md-3 form-label">
                  Lowest Score
                  <span class="text-danger">*</span>
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'trainer-compentency-criteria-edit',
                      'trainer-compentency-criteria-create',
                    ])
                  "
                >
                  <input
                    type="number"
                    id="lowest_score"
                    name="lowest_score"
                    class="form-control"
                    @input="lowScoreChanges($event.target.value)"
                    placeholder="Lowest Score"
                    v-model="criteria.lowest_score"
                    :class="{
                      'p-invalid':
                        v$.criteria.lowest_score.$error ||
                        errorFor('lowest_score'),
                    }"
                  />
                  <v-errors
                    :serverErrors="errorFor('lowest_score')"
                    :vuelidateErrors="{
                      errors: v$.criteria.lowest_score.$errors,
                      value: 'Lowest Score',
                    }"
                  ></v-errors>
                </div>

                <div
                  class="col-md-6"
                  v-else-if="
                    hasPermissions(['trainer-compentency-criteria-list'])
                  "
                >
                  <input
                    type="number"
                    id="lowest_score"
                    name="lowest_score"
                    class="form-control"
                    v-model="criteria.lowest_score"
                    disabled
                  />
                </div>
              </div>

              <div class="row mb-3">
                <label for="highest_score" class="col-md-3 form-label">
                  Highest Score
                  <span class="text-danger">*</span>
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'trainer-compentency-criteria-edit',
                      'trainer-compentency-criteria-create',
                    ])
                  "
                >
                  <input
                    type="number"
                    id="highest_score"
                    name="highest_score"
                    class="form-control"
                    @input="highScoresChanges($event.target.value)"
                    placeholder="Highest Score"
                    v-model="criteria.highest_score"
                    :class="{
                      'p-invalid':
                        v$.criteria.highest_score.$error ||
                        errorFor('highest_score'),
                    }"
                  />
                  <v-errors
                    :serverErrors="errorFor('highest_score')"
                    :vuelidateErrors="{
                      errors: v$.criteria.highest_score.$errors,
                      value: 'Highest Score',
                    }"
                  ></v-errors>
                </div>

                <div
                  class="col-md-6"
                  v-else-if="
                    hasPermissions(['trainer-compentency-criteria-list'])
                  "
                >
                  <input
                    type="number"
                    id="highest_score"
                    name="highest_score"
                    class="form-control"
                    v-model="criteria.highest_score"
                    disabled
                  />
                </div>
              </div>

              <div
                class="row mb-3"
                v-if="
                  currentRouteName === 'trainer-competency-criteria-update'
                "
              >
                <label for="status" class="col-md-3 form-label">
                  Status
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'trainer-compentency-criteria-edit',
                      'trainer-compentency-criteria-create',
                    ])
                  "
                >
                  <select name="status" v-model="criteria.status" class="form-control">
                    <option :value="1">Active</option>
                    <option :value="0">Inactive</option>
                  </select>
                </div>

                <div
                  class="col-md-6"
                  v-else-if="
                    hasPermissions(['trainer-compentency-criteria-list'])
                  "
                >
                  <select
                    name="status"
                    class="form-control"
                    disabled
                  >
                    <option :value="1">Active</option>
                    <option :value="0">Inactive</option>
                  </select>
                </div>
              </div>

              <div class="row mb-3" v-if="!criteria.status">
                <label for="remark" class="col-md-3 form-label">
                  Remark
                  <!-- <span class="text-danger">*</span> -->
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'trainer-compentency-criteria-edit',
                      'trainer-compentency-criteria-create',
                    ])
                  "
                >
                  <textarea
                    type="text"
                    id="remark"
                    name="remark"
                    class="form-control"
                    placeholder="Remark"
                    v-model="criteria.remark"
                    :class="{
                      'p-invalid': v$.criteria.remark.$error || errorFor('remark'),
                    }"
                  >
                  </textarea>
                  <v-errors
                    :serverErrors="errorFor('remark')"
                    :vuelidateErrors="{
                      errors: v$.criteria.remark.$errors,
                      value: 'Remark',
                    }"
                  ></v-errors>
                </div>

              </div>
              <!-- end row -->
              <div
                class="row"
                v-if="
                  hasPermissions([
                    'trainer-compentency-criteria-edit',
                    'trainer-compentency-criteria-create',
                  ])
                "
              >
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'trainer-competency-criteria' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName ===
                        'trainer-competency-criteria-update'
                          ? updateCriteria()
                          : createCriteria()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
import { levels } from "../../../const";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    vSelect,
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      criteria: {
        competency_id: "",
        program_ids : [],
        level : "",
        assessment_criteria_id: "",
        lowest_score: "",
        highest_score: "",
        all_check : 1,
        status: 1,
        remark: null,
        is_presentation : 0,
        type: "Competency"
      },
      getLevel : levels,
      assessmentCriteria: [],
      programList : this.$store.getters["odoo/getAllPrograms"],
      competencyList : [],
      baseUrl: process.env.VUE_APP_BASE_URL,
       
      loading: false,
      isLoading: false,
    };
  },
  validations() {
    return {
      criteria: {
        competency_id : { required },
        program_ids : this.criteria.all_check ? '' :{ required },
        level : { required },
        assessment_criteria_id: { required },
        lowest_score: { required },
        highest_score: { required },
        all_check : { required },
        remark : this.criteria.status ? '' : {required},
      },
    };
  },
  methods: {
    lowScoreChanges(val) {
      this.criteria.lowest_score =
        val && Math.abs(val) >= 1 && Math.abs(val) <= 5 ? Math.abs(val) : null;
      return this.criteria.lowest_score;
    },
    highScoresChanges(val) {
      this.criteria.highest_score =
        val && Math.abs(val) >= 1 && Math.abs(val) <= 5 ? Math.abs(val) : null;
      return this.criteria.highest_score;
    },

    selectedProgram(option) {

      return (!this.criteria.program_ids.length )  ? true :
      this.criteria.program_ids.find((p) => p.id == option.id) === undefined

    },

    async getCompetency() {

      await axios.get(`${this.baseUrl}admin/v1/competency`)
                .then((res) => this.competencyList = res.data.data)

    },

    async getAssessmentCriteria() {
      let tempData = []
      await axios.get(`${this.baseUrl}admin/v2/assessment-criteria?status=1&type=OJT`)
                  .then((res) =>{
                        tempData  = res.data.data
                    })
      await axios.get(`${this.baseUrl}admin/v2/assessment-criteria?status=1&type=In-Class`)
        .then((res) =>{
              let data =  res.data.data
              this.assessmentCriteria = [...data, ...tempData]
          })              
    }, 

    async getCriteriaById() {
      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v2/trainer-criteria/${this.$route.params.id}`
        )
        .then((response) => {
          const result = response.data.data;
        
          this.criteria.all_check = result.all_check
          this.criteria.program_ids = result.all_check ? [] : result.programs.map((val)=>val.id)
          this.criteria.assessment_criteria_id = result.assessment_criteria_id;
          this.criteria.lowest_score = result.lowest_score;
          this.criteria.highest_score = result.highest_score;
          this.criteria.level = result.level
          this.criteria.status = result.status;
          this.criteria.remark = !result.status ? result.remark : null;
          this.criteria.competency_id = result.competency.id
        })
        .catch(() => {
          this.toast.error("Not Found Criteria!");
        });
      this.loading = false;
    },

    async createCriteria() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      this.isLoading = true;
      let sendData = { ...this.criteria };
      if (sendData.all_check) {
        sendData.program_ids = this.program_id_list;
      }
      
      await axios
        .post(
          `${this.baseUrl}admin/v2/trainer-criteria`,
          sendData
        )
        .then(() => {
          this.$router.push({ name: "trainer-competency-criteria" });
          this.toast.success("Successfully Created Criteria!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },

    async updateCriteria() {

      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      this.isLoading = true;

      let sendData = { ...this.criteria };
      if (sendData.all_check) {
        sendData.program_ids = this.program_id_list;
      }

      axios
        .put(
          `${this.baseUrl}admin/v2/trainer-criteria/${this.$route.params.id}`,
          sendData
        )
        .then(() => {
          this.$router.push({ name: "trainer-competency-criteria" });
          this.toast.success("Successfully Criteria Updated!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },
  },
  async created() {
    await this.getAssessmentCriteria()
    await this.getCompetency()
   
    if (this.currentRouteName == "trainer-competency-criteria-update") {
      this.getCriteriaById();
    }
  },
  computed: {

    currentRouteName() {
      return this.$route.name;
    },
    filterAssessmentCriteria() {
      if(this.criteria.all_check) {
            return  this.assessmentCriteria.filter((val) => {
                  return val.all_check && val.level == this.criteria.level
          })}else{
          return this.assessmentCriteria.filter((val)  => {
				if(this.criteria.program_ids.length){
					let p_ids = val.programs.map((p) => p.id)
                  let flag = true;
                       Object.values(this.criteria.program_ids).some((p) => {
                          if(! p_ids.includes(p)){
                            flag = false;
                          }
        
                      })
                    return flag && val.level == this.criteria.level;
				}			
                  
          })
        }
    },
  },

  // watch: {
  //   'criteria.level'(){
  //     this.criteria.assessment_criteria_id = "";
  //   }
  // }
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
</style>
